import Error from "@@/common/Error.vue";
import {
// eslint-disable-next-line no-unused-vars
Image as VImage, List,
// eslint-disable-next-line no-unused-vars
Progress as VProgress,
// eslint-disable-next-line no-unused-vars
CountDown } from "vant";
import { walletQuery, nftList } from "@api/index";
import { mapState } from "vuex";
import { env } from "md-base-tools/env";
// import header from "md-base-tools/header";
import domain from "@/service/domain";
import Clipboard from "clipboard";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    List,
    Error
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      request_id: "",
      refreshing: false,
      finished: false,
      userInfo: {},
      loading: true,
      productList: [],
      toUserid: "",
      page: 1,
      timer: null
    };
  },
  async mounted() {
    this.toUserid = this.$route.params.id;
    // eslint-disable-next-line no-undef
    userHeader.titleText("数字藏品");
    await this.walletQuery();
    await this.nftList();
  },
  methods: {
    copySendProLink() {
      var clipboard = new Clipboard("#copycode");
      clipboard.on("success", () => {
        mdToast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        mdToast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    back() {
      history.back();
    },
    tontf(item) {
      location.href = `${domain.wap}/digital/detail/${item.stock_hash}`;
    },
    async walletQuery() {
      const {
        data
      } = await walletQuery({
        other_user_id: this.userId
      });
      this.userInfo = data;
      // console.log(status, data);
    },
    async getNext(e) {
      if (e === 1) {
        this.productList = [];
      }
      this.page = e;
      if (this.is_next || e === 1) {
        await this.nftList();
      }
    },
    onRefresh() {
      this.finished = false;
      this.nftList();
    },
    onLoad() {
      if (this.refreshing) {
        this.page = 1;
        this.refreshing = false;
        this.loading = true;
      }
      if (this.loading && this.is_next) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.nftList();
          this.page++;
        }, 100);
      } else {
        this.loading = false;
      }
    },
    async nftList() {
      const {
        status,
        data
      } = await nftList({
        other_user_id: this.userId,
        request_id: this.request_id,
        page: this.page,
        page_size: 10
      });
      if (status == 0) {
        this.loading = false;
        this.request_id = data.request_id;
        this.is_next = data.is_next;
        this.productList.push(...data.list);
      }
    }
  }
};